<template>
  <v-container grid-list-xs>
    <v-row>
      <v-col>
        <p class="subtitle-1 mb-0">
          Selecione o Público Alvo da Campanha
        </p>
        <p class="caption">
          Selecione as funções que participarão da campanha
        </p>

        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="funcoes"
          item-key="id_funcao"
          class="elevation-1"
          :loading="funcoes.length < 1"
          :sort-by="['id_band', 'cod_funcao']"
          :sort-desc="[false, false]"
          :search="search"
          multi-sort
          loading-text="Carregando, aguarde ..."
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar..."
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.selected`]="{ item }">
            <v-checkbox
              @click="selectedPublicoAlvo(item)"
              v-model="item.selected"
            ></v-checkbox>
          </template>
          <template v-slot:[`item.descricao`]="{ item }">
            {{ item.descricao | TitleCase }}
          </template>
          <template v-slot:[`item.id_band`]="{ item }">
            <BaseSegmento :segmento="item.id_band" />
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip :color="statusFormated(item).color" dark>
              {{ statusFormated(item).text }}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              icon
              class="ml-1"
              @click="fetchColaboradores(item)"
              color="primary"
              dark
            >
              <v-icon>mdi-account-search</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalColaboradoresPublicoAlvo
      :items="colaboradoresEmpresa.data"
      :show="colaboradoresEmpresa.show"
      @close="colaboradoresEmpresa.data = []"
    />
  </v-container>
</template>

<script>
import serviceCampanha from "@/services/campanha";
import colaboradorService from "@/services/colaborador";
import { mapGetters } from "vuex";
import initMixin from "../../initMixin";
export default {
  name: "FormPublicoAlvo",
  components: {
    BaseSegmento: () => import("@/components/shared/BaseSegmento"),
    ModalColaboradoresPublicoAlvo: () =>
      import(
        "@/components/campanha/cadastro/PublicoMeta/ModalColaboradoresPublicoAlvo"
      )
  },
  mixins: [initMixin],
  data() {
    return {
      search: "",
      headers: [
        { text: "selecione", value: "selected" },
        { text: "Segmento", value: "id_band" },
        {
          text: "Código da Função",
          align: "start",
          value: "cod_funcao"
        },
        { text: "Descrição", value: "descricao" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "actions", sortable: false, align: "center" }
      ],
      funcoes: [],
      colaboradoresEmpresa: {
        data: [],
        show: false
      }
    };
  },
  computed: {
    ...mapGetters({
      getFuncoes: "campanha/getFuncoes",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes"
    }),
    filtroBandeira() {
      let arrBand = [];
      this.getEmpresasParticipantes.map(empresa => {
        if (!arrBand.includes(empresa.id_band)) {
          arrBand.push(empresa.id_band);
        }
      });
      return arrBand.join(",");
    }
  },

  mounted() {
    this.initActionFuncaoPublicoAlvo();
  },

  watch: {
    filtroBandeira() {
      this.initActionFuncaoPublicoAlvo();
    },
    getFuncoes() {
      this.funcoes = this.getFuncoes;
    }
  },

  methods: {
    async selectedPublicoAlvo(item) {
      try {
        let form = {
          id_campanha: this.$route.params.idCampanha,
          id_funcao: item.id_funcao,
          id_band: item.id_band
        };
        let msg = "";
        let type = "";
        if (item.selected) {
          await serviceCampanha.postCampanhaPublico(form);
          msg = `${item.descricao}  adicionado a campanha`;
          type = "success";
        } else {
          await serviceCampanha.deleteCampanhaPublico(item.id_campanha_publico);
          msg = `${item.descricao}  removido da campanha`;
          type = "warning";
        }

        this.$notify({
          type: type,
          text: msg
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Erro: ${error.response.data.message}, ${item.descricao} `
        });
      } finally {
        await this.initActionFuncaoPublicoAlvo();
      }
    },
    statusFormated(item) {
      let status = {
        text: "Indefinido",
        color: "gray"
      };

      if (item.ativo.toLowerCase() === "s") {
        (status.text = "Sim"), (status.color = "green");
      }

      if (item.ativo.toLowerCase() === "n") {
        (status.text = "Não"), (status.color = "red");
      }

      return status;
    },

    async fetchColaboradores(item) {
      try {
        const filter = {
          ativo: "S",
          id_funcao: item.id_funcao,
          id_empresa: "",
          per_page: -1
        };

        filter.id_empresa = this.getEmpresasParticipantes.map(
          empresa => empresa.id_empresa
        );

        let response = await colaboradorService.getColaboradoresEmpresa(filter);

        if (response.data.data.length <= 0) {
          this.$notify({
            type: "error",
            text: "A função selecionada não possui colaboradores"
          });
        }
        this.colaboradoresEmpresa.data = response.data.data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao buscar colaboradores"
        });
      }
    }
  }
};
</script>
