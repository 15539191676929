var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"subtitle-1 mb-0"},[_vm._v(" Selecione o Público Alvo da Campanha ")]),_c('p',{staticClass:"caption"},[_vm._v(" Selecione as funções que participarão da campanha ")]),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.funcoes,"item-key":"id_funcao","loading":_vm.funcoes.length < 1,"sort-by":['id_band', 'cod_funcao'],"sort-desc":[false, false],"search":_vm.search,"multi-sort":"","loading-text":"Carregando, aguarde ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.selectedPublicoAlvo(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.descricao))+" ")]}},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusFormated(item).color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusFormated(item).text)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.fetchColaboradores(item)}}},[_c('v-icon',[_vm._v("mdi-account-search")])],1)]}}],null,true)})],1)],1),_c('ModalColaboradoresPublicoAlvo',{attrs:{"items":_vm.colaboradoresEmpresa.data,"show":_vm.colaboradoresEmpresa.show},on:{"close":function($event){_vm.colaboradoresEmpresa.data = []}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }